import React from 'react';

function Help() {
  return (
    <div>
      <h4>Indicadores</h4>

      <p>
        <a
          className="mod-ind morado"
          href="https://comiteecologicointerescolar.org/2022/02/23/efectos-a-la-salud-pm10-y-pm2-5">
          PM 10
        </a> &emsp;
        Material Particulado menor a 10 micrómetros Mezcla compleja de partículas líquidas o sólidas que
        provienen de fuentes naturales o de fuentes antropogénicas, poseen un tamaño aerodinámico menor a 10 micrómetros.
      </p>

      <p>
        <a
          className="mod-ind morado"
          href="https://comiteecologicointerescolar.org/2022/02/23/efectos-a-la-salud-pm10-y-pm2-5">
          PM 2.5
        </a>
        &emsp; Mezcla Compleja de Partículas líquidas o sólidas que provienen principalmente de fuentes antropogénicas, poseen un
        tamaño aerodinámico menor a 2.5 micrómetros.
      </p>
      <p>
        <a
          className="mod-ind morado"
          href="https://comiteecologicointerescolar.org/2022/02/23/efectos-a-la-salud-de-ozono">
          O3
        </a>
        &emsp; Ozono Gas compuesto por 3 átomos de oxigeno que se encuentra principalmente en la estratosfera, puede formarse a
        nivel superficial debido a condiciones de alta radiación y temperatura.
      </p>
      <p>
        <a
          className="mod-ind morado"
          href="https://comiteecologicointerescolar.org/2022/02/23/efectos-a-la-salud-de-monoxido-de-carbono">
          CO
        </a>
        &emsp; Monóxido de Carbono Gas incoloro que se forma principalmente por la combustión de gasolinas, leña o carbón,
        este compuesto, en altas concentraciones puede ser muy nocivo a la salud.
      </p>
      <p>
        <a
          className="mod-ind morado"
          href="https://comiteecologicointerescolar.org/2022/02/23/efectos-a-la-salud-de-dioxido-de-azufre">
          SO2
        </a>
        &emsp; Dióxido de Azufre Es un gas formado por 2 átomos de oxígeno y uno de azufre, se forma principalmente por
        la combustión de combustibles fósiles con alto contenido de azufre.
      </p>
      <p>
        <a
          className="mod-ind morado"
          href="https://comiteecologicointerescolar.org/2022/02/23/efectos-a-la-salud-de-oxidos-de-nitrogeno">
          NO2
        </a>
        &emsp; Dióxido de Nitrógeno Molécula con 2 átomos de oxígeno y uno de Nitrógeno que se produce principalmente por
        la combustión de combustibles fósiles empleados en vehículos y plantas de energía.
      </p>
    </div>
  );
}

export default Help;
